.rbc-event {
 
}

.rbc-event-content{
    font-weight: 400 !important;
} 

.rbc-event-label{
    color: black !important;
}
