/* input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.custom-file-input {
    display: inline-block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: 150px; /* Adjust the width as needed */
/* }
  
  .custom-file-input input {
    position: absolute;
    font-size: 100px;
    right: 0;
    top: 0;
    opacity: 0;
  }
  
  .file-input-label {
    display: inline-block;
    padding: 8px 12px;
    background-color: #0077CC; 
    color: white; 
    border: 1px solid #0077CC; 
    border-radius: 4px;
    cursor: pointer;
  } */
.image {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;

}

/* 
.image:hover { 
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);} */