
@font-face {
  font-family: "Inter";
  src: local("InterFonts"),
    url("./fonts/Inter-Medium.ttf") format("truetype");
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mlt-s {
  min-width: 400px !important;
  margin-top: -1px;
}

/* .sp {
  
  color: #20A8D3;
} */

::-webkit-scrollbar {
  width: 10px; 
}

::-webkit-scrollbar-thumb {
  cursor:grab;
  background-color: #afafaf94; 
  border-radius: 3px; 
}
.error-message{
  color:rgba(230, 74, 74, 0.818);
  margin: 10px 0 ;
}
.addPatientForm{
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  .myform {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    .line {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 2rem;
      flex-wrap: wrap;
      .duble-size {
        width: 100%;
      }
      .half-size {
        width: 50%;
      }
    }
  }
}

.h-inherit {
  height: inherit;
}