@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: local("InterFonts"), url("./fonts/Inter-Medium.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.debug {
  border: 1px solid red !important;
}

.debug * {
  border: 1px solid green !important;
}

.debug * * {
  border: 1px solid blue !important;
}
.editor-wrapper {
  border: 1px solid #dadada;
  border-radius: 10.22px;
}

.editor-content {
  background-color: white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
